@import '_functions.scss';

// Paths

$img-path: "assets/img/";
$icon-path: "#{$img-path}/icon/";

// Colors

$white: #FFFFFF;
$black: #000000;
$primary-color: #6872E3;
$mk-color: #e83479;
$facebook-color: #4267B2;
$gray-dark: #2D3446;
$text-gray: $gray-dark;
$text-gray-light: #8898AA;
$text-gray-inactive: #C8D2DD;
$border-color: $text-gray-light;
$border-color-light: $text-gray-inactive;
$border-color-ligher: #E4EBF3;
$border-color-inactive: $text-gray-inactive;
$section-separator-color: #EFF4FB;
$danger-color: #FF5A5F;
$success-color: #3ECF8E;
$text-green-special: #55D9C2;
$text-form-selected: #333333;

$btn-default: $border-color-light;
$btn-dark: $gray-dark;
$btn-primary: $primary-color;


$slider-handle-background: $section-separator-color;
$checkbox-toggle-background: $border-color-light;

$active-filter-background: #2D34461A;

$tag-background: $gray-dark;
$tag-light-background: $active-filter-background;

$background-gray-light: #F6F9FC;
$background-gray-lighter: #FAFCFD;
$background-gray: $background-gray-light; // ???

$table-th-background: $background-gray-light;

$input-background-color: $white;

// Font font family

$font-family-default: 'Montserrat', sans-serif;
$font-family-roboto: 'Roboto', sans-serif;

// Font font-weight

$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;

// Font size

$font-size-default-px: 15px;
$font-size-default: px2em($font-size-default-px);
$line-height-default: 21px;
