@import '../../styles/abstract.scss';

.inside, .profile {
	@include media-above(md) {
		padding-top: 80px;

		&.incPaddingTop {
			padding-top: 112px; // 80 + 32px header notification
		}
	}
}

// Detail has no fixed header
.inside[class*="drazba_default"] {
	padding-top: 0!important;

	header {
		position: relative!important;
	}
}

.auth {
	.goBack {
		position: absolute;
		right: 25px;
		top: 25px;
		font-size: 24px;

		&:hover {
			color: $primary-color;
		}
	}

	.hero {
		width: 100%;
		height: 100vh;
		background: transparent linear-gradient(140deg, $primary-color 0%, #D390D8 100%) 0% 0% no-repeat padding-box;
		color: $white;
		@include flexbox();
		@include flex-direction(column);
		@include justify-content(center);

		@include media-below(lg) {
			padding: 150px 50px 50px 0px;
		}

		@include media-below(md) {
			height: auto;
		}

		@include media-below(sm) {
			padding-top: 50px;
		}

		.aligned {
			max-width: 550px;
			margin-left: 100px;

			@include media-below(lg) {
				margin-left: 50px;
			}

			@include media-below(sm) {
				margin-left: 25px;
			}
		}

		h1 {
			@include h1();
			color: $white;

			@include media-below(lg) {
				font-size: 40px;
			}
		}

		p {
			margin: 40px 0;
			font-size: 17px;
			line-height: 24px;

			@media(max-height: 749px) {
				margin: 15px 0;
			}
		}

		ul {
			margin-bottom: 40px;

			@media(max-height: 749px) {
				margin-bottom: 20px;
			}

			li {
				&:not(:last-child) {
					margin-bottom: 10px;
				}
			}
		}

		.logo {
			position: absolute;
			top: 60px;
			left: 100px;
			@include background-image('logo-drazbe-white.svg', 197, 32);
			display: block;

			@include media-below(lg) {
				left: 50px;
			}

			@include media-below(sm) {
				display: none;
			}
		}
	}

	.left {
		@include media-below(md) {
			@include order(2);
		}
	}

	.main {
		position: relative;
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);
		font-size: 12px;

		@include media-below(lg) {
			height: auto;
			padding: 50px 0 50px;
		}

		.logo {
			display: none;
		}

		@include media-below(md) {
			height: auto;
			padding: 40px 0 60px 0;
			@include order(1);

			.logo {
				position: relative;
				margin: 0 auto 40px auto;
				@include background-image('logo-drazbe-color.svg', 197, 32);
				display: block;
			}
		}

		.aligned {
			width: 420px;

			@include media-below(sm) {
				width: 100%;
				padding: 0 15px;
			}
		}

		.footer {
			@include flexbox();
			justify-content: center;
			font-size: inherit;
			color: $text-gray-light;
			padding-top: 10px;

			/*position: absolute;
			bottom: 30px;
			width: 100%;
			text-align: center;
			left: 0;
			font-size: inherit;
			color: $text-gray-light;*/
		}
	}
}

.hero {
	@include media-below(md) {
		main {
			position: relative;
			top: -80px;
		}
	}
}

.profile {
	$profile-layout-min-h: 500px;

	main {
		padding: 40px 0;
		min-height: $profile-layout-min-h;

		@include media-below(sm) {
			padding: 30px 0;
		}
	}

	.sidebarWrapper {
		margin-bottom: 40px;
		min-height: $profile-layout-min-h;

		@include media-below(md) {
			min-height: 0;
		}

		@include media-above(sm) {
			border-right: 1px solid $section-separator-color;
			width: 87%;
			margin: 0;
		}

		nav {
			.panelHeader {
				display: none;
			}
		}

		@include media-below(sm) {
			h2 {
				@include flexbox();
				@include align-items(flex-start);
				line-height: 100%;

				[class*="toggler"] {
					margin-right: 10px!important;
				}

				+ nav {
					@include mobile-navigation-fixed();
					display: none;

					a {
						font-weight: 600;
						font-size: 15px;
					}

					.panelHeader {
						@include flexbox();
						@include justify-content(space-between);
						@include align-items(flex-start);

						h2 {
							display: block;
							font-size: 24px;
					    font-weight: 700;
					    text-align: left;
					    width: 100%;
					    margin: 8px 0 25px 0;
						}

						button {
							margin-top: 10px;
						}
					}
				}

				&.open + nav {
					display: block;
				}
			}
		}
	}

	.sidemenu {
		margin-top: 36px;

		@include media-below(sm) {
			margin-top: 15px;
		}

		a {
			display: block;
			margin-bottom: 20px;
			color: $gray-dark;

			&.active, &:hover {
				color: $primary-color;
				text-decoration: none;
			}

			&.active {
				font-weight: $font-medium;
			}
		}
	}

	.main {
		form > h3, > h3, > h2 {
			margin-bottom: 60px;

			@include media-below(sm) {
				margin-bottom: 30px;
				font-size: 24px;
				font-weight: $font-semibold;
			}
		}
	}
}
